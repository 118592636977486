@import "../../global";

#editor {
	flex: 3;
	display: block;
	outline: none;
	padding: 2rem;
	border: none;
	background-color: $color-background-dark;
	color: $color-text-dark;

	&::first-line {
		font-weight: bold;
		font-size: 1.2rem;
		line-height: 2rem;
	}
}
