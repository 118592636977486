@import "../../global";

.list {
	padding: 0;
	margin: 0;
	list-style: none;
	flex: 1;
	background-color: $color-background;
	color: $color-text;
	user-select: none;
	overflow-x: auto;

	li {
		padding: 1rem;
		cursor: pointer;

		&.selected {
			background-color: #4a4a4a;
		}

		&:first-child {
			font-style: italic;
		}

		+ li {
			border-top: 1px solid rgba($color-text, 0.2);
		}

		h1 {
			font-size: 1rem;
			margin: 0;
			padding: 0;
		}

		div {
			font-size: 0.8rem;
		}
	}
}
