@import "global";

html,
body,
#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  align-items: stretch;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($color-text, 0.2);
  border-radius: 10px;
}
